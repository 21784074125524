<msep-page-title id="header">
  <h1>Job Feed Guidance</h1>
</msep-page-title>

<mat-drawer-container class="flex flex-col flex-gt-sm-row flex-wrap">
  <mat-drawer mode="side" opened class="hide show-gt-sm flex flex-gt-sm-19">
    <mat-nav-list>
      <ng-container *ngFor="let navLink of navLinks">
        <a
          role="link"
          class="nav-link"
          [routerLink]="[urlPath]"
          [fragment]="navLink.fragment"
        >
          {{ navLink.title }}
        </a>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-nav-list>

    <p><strong>Have any further questions?</strong></p>
    <a
      role="link"
      mat-raised-button
      color="primary"
      [routerLink]="['/notice-and-consent', 'contact-us']"
      >Contact Us</a
    >
  </mat-drawer>

  <mat-drawer-content class="flex-100 flex-gt-sm-80">
    <div class="drawer-content-container">
      <h2 id="general-job-feed-guidance">General Job Feed Guidance</h2>
      <mat-card>
        <p>
          The MSEP Partner Portal uses an automated importing of job feeds.
          Feeds can only be received via HTTPS. The portal is unable to accept
          delivery as SFTP, FTP or daily emails due to security regulations.
          <em
            >MSEP is only able to access a partner API via the use of basic
            authorization (username/password) or with a partner provided API
            key.
          </em>
        </p>
        <p>
          Job feeds - at a minimum - must include the job title, job
          description, and the full job URL, which must be unique to that
          specific job post. We also recommend including the city, state, postal
          code, and country fields. This will allow military spouses to easily
          target jobs that you provide in their area. The addition of job type,
          preferred qualifications, education, and salary/hourly fields will
          further enhance the overall search results for your company.
        </p>
        <p>
          The portal technical team posts all jobs on the MSEP Partner Portal.
          They are responsible for testing, parsing, importing and ensuring the
          imports will work successfully on the MSEP Partner Portal. However,
          the portal contract technical team <strong>cannot</strong> make any
          edits to your feed.
        </p>
        <p>
          If you would like to schedule a conference call for clarification on
          this process, please submit a
          <a role="link" [routerLink]="['/notice-and-consent', 'contact-us']"
            >Contact Us</a
          >. Prior to the conference call, we prefer that you provide a fully
          constructed test feed for us to review if one is available. This will
          allow the technical team to review the feed structure and provide
          feedback.
        </p>
        <msep-alert [type]="alertType">
          <p>
            <strong class="uppercase">Note:</strong>
          </p>
          <ul>
            <li>
              When configuring your job feeds, we match your source field names
              to our destination field names. Your feeds do not need to match
              our field names exactly, however, the types do need to match and
              the feed source must have the minimum required fields (title,
              description, URL).
            </li>
            <li>
              All job feed data must follow UTF-8 character encoding standards.
            </li>
          </ul>
        </msep-alert>
      </mat-card>

      <h2 id="required-for-job-postings">Required for Job Postings</h2>
      <div class="table-wrap mat-elevation-z4">
        <table
          class="striped"
          mat-table
          [dataSource]="requiredFields"
          aria-label="Required fields for Job Postings"
        >
          <ng-container matColumnDef="field">
            <th mat-header-cell remove-role *matHeaderCellDef>Field</th>
            <td mat-cell remove-role *matCellDef="let field">
              <code>{{ field.field }}</code>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell remove-role *matHeaderCellDef>Type</th>
            <td mat-cell remove-role *matCellDef="let field">
              <code>{{ field.type }}</code>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell remove-role *matHeaderCellDef>Description</th>
            <td mat-cell remove-role *matCellDef="let field">
              <span [innerHTML]="field.description"></span>
            </td>
          </ng-container>
          <tr mat-header-row remove-role *matHeaderRowDef="fieldColumns"></tr>
          <tr
            mat-row
            remove-role
            *matRowDef="let row; columns: fieldColumns"
          ></tr>
        </table>
      </div>
      <h2 id="highly-recommended-for-job-postings">
        Highly Recommended for Job Postings
      </h2>
      <div class="table-wrap mat-elevation-z4">
        <table
          class="striped"
          mat-table
          [dataSource]="highlyRecommendedData"
          aria-label="Highly Recommended Fields for Job Postings"
        >
          <ng-container matColumnDef="field">
            <th mat-header-cell remove-role *matHeaderCellDef>Field</th>
            <td mat-cell remove-role *matCellDef="let field">
              <code>{{ field.field }}</code>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell remove-role *matHeaderCellDef>Type</th>
            <td mat-cell remove-role *matCellDef="let field">
              <code>{{ field.type }}</code>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell remove-role *matHeaderCellDef>Description</th>
            <td mat-cell remove-role *matCellDef="let field">
              <span [innerHTML]="field.description"></span>
            </td>
          </ng-container>
          <tr mat-header-row remove-role *matHeaderRowDef="fieldColumns"></tr>
          <tr
            mat-row
            remove-role
            *matRowDef="let row; columns: fieldColumns"
          ></tr>
        </table>
      </div>
      <!-- can we update this to allow content projection? -->
      <msep-alert [type]="alertType">
        <p>
          <strong class="uppercase">Note:</strong> All fields may be wrapped in
          &lt;![CDATA[]]&gt;.
        </p>
      </msep-alert>
      <h2 id="example-xml-feed">Example XML Feed</h2>
      <mat-card>
        <pre [innerText]="exampleXml"></pre>
      </mat-card>
      <h2 id="example-json-feed">Example JSON Feed</h2>
      <mat-card>
        <div class="sample-api-endpoint">
          <button
            mat-raised-button
            color="primary"
            (click)="onClickExample(exampleJsonUrl)"
          >
            JSON api endpoint
          </button>
          <mat-icon
            matTooltip="This is an example of how a job feed api endpoint should function."
            matTooltipPosition="right"
            aria-label="Info - This is an example of how a job feed api endpoint should function."
            fontIcon="info"
          >
          </mat-icon>
        </div>
        <pre [innerText]="exampleJson"></pre>
      </mat-card>
      <h2 id="example-csv-feed">Example CSV Feed</h2>
      <mat-card>
        <div class="sample-api-endpoint">
          <button
            mat-raised-button
            color="primary"
            (click)="onClickExample(exampleCsvUrl)"
          >
            CSV api endpoint
          </button>
          <mat-icon
            matTooltip="This is an example of how a job feed api endpoint should function."
            matTooltipPosition="right"
            aria-label="Info - This is an example of how a job feed api endpoint should function."
            fontIcon="info"
          >
          </mat-icon>
        </div>
        <div class="csv-table-wrap mat-elevation-z4">
          <table
            class="striped"
            mat-table
            [dataSource]="exampleCsv"
            aria-label="Example CSV Feed"
          >
            <ng-container matColumnDef="title">
              <th mat-header-cell remove-role *matHeaderCellDef>title</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.title }}
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell remove-role *matHeaderCellDef>description</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.description }}
              </td>
            </ng-container>
            <ng-container matColumnDef="url">
              <th mat-header-cell remove-role *matHeaderCellDef>url</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.url }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jobIdentifier">
              <th mat-header-cell remove-role *matHeaderCellDef>
                jobIdentifier
              </th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.jobIdentifier }}
              </td>
            </ng-container>
            <ng-container matColumnDef="industry">
              <th mat-header-cell remove-role *matHeaderCellDef>industry</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.industry }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jobType">
              <th mat-header-cell remove-role *matHeaderCellDef>jobType</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.jobType }}
              </td>
            </ng-container>
            <ng-container matColumnDef="compensationType">
              <th mat-header-cell remove-role *matHeaderCellDef>
                compensationType
              </th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.compensationType }}
              </td>
            </ng-container>
            <ng-container matColumnDef="hourlyRate">
              <th mat-header-cell remove-role *matHeaderCellDef>hourlyRate</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.hourlyRate }}
              </td>
            </ng-container>

            <ng-container matColumnDef="minSalary">
              <th mat-header-cell remove-role *matHeaderCellDef>minSalary</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.minSalary }}
              </td>
            </ng-container>
            <ng-container matColumnDef="maxSalary">
              <th mat-header-cell remove-role *matHeaderCellDef>maxSalary</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.maxSalary }}
              </td>
            </ng-container>
            <ng-container matColumnDef="yearsOfExperience">
              <th mat-header-cell remove-role *matHeaderCellDef>
                yearsOfExperience
              </th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.yearsOfExperience }}
              </td>
            </ng-container>
            <ng-container matColumnDef="career">
              <th mat-header-cell remove-role *matHeaderCellDef>career</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.career }}
              </td>
            </ng-container>
            <ng-container matColumnDef="jobOpenings">
              <th mat-header-cell remove-role *matHeaderCellDef>jobOpenings</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.jobOpenings }}
              </td>
            </ng-container>
            <ng-container matColumnDef="education">
              <th mat-header-cell remove-role *matHeaderCellDef>education</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.education }}
              </td>
            </ng-container>
            <ng-container matColumnDef="minQualifications">
              <th mat-header-cell remove-role *matHeaderCellDef>
                minQualifications
              </th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.minQualifications }}
              </td>
            </ng-container>
            <ng-container matColumnDef="prefQualifications">
              <th mat-header-cell remove-role *matHeaderCellDef>
                prefQualifications
              </th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.prefQualifications }}
              </td>
            </ng-container>
            <ng-container matColumnDef="address">
              <th mat-header-cell remove-role *matHeaderCellDef>address</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.address }}
              </td>
            </ng-container>
            <ng-container matColumnDef="address2">
              <th mat-header-cell remove-role *matHeaderCellDef>address2</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.address2 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="city">
              <th mat-header-cell remove-role *matHeaderCellDef>city</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.city }}
              </td>
            </ng-container>
            <ng-container matColumnDef="state">
              <th mat-header-cell remove-role *matHeaderCellDef>state</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.state }}
              </td>
            </ng-container>
            <ng-container matColumnDef="postal">
              <th mat-header-cell remove-role *matHeaderCellDef>postal</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.postal }}
              </td>
            </ng-container>
            <ng-container matColumnDef="country">
              <th mat-header-cell remove-role *matHeaderCellDef>country</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.country }}
              </td>
            </ng-container>
            <ng-container matColumnDef="isTelework">
              <th mat-header-cell remove-role *matHeaderCellDef>isTelework</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.isTelework }}
              </td>
            </ng-container>
            <ng-container matColumnDef="isRemoteJob">
              <th mat-header-cell remove-role *matHeaderCellDef>isRemoteJob</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.isRemoteJob }}
              </td>
            </ng-container>
            <ng-container matColumnDef="isHotJob">
              <th mat-header-cell remove-role *matHeaderCellDef>isHotJob</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.isHotJob }}
              </td>
            </ng-container>
            <ng-container matColumnDef="tags">
              <th mat-header-cell remove-role *matHeaderCellDef>tags</th>
              <td mat-cell remove-role *matCellDef="let field">
                {{ field.tags }}
              </td>
            </ng-container>
            <tr
              mat-header-row
              remove-role
              *matHeaderRowDef="exampleCsvColumns"
            ></tr>
            <tr
              mat-row
              remove-role
              *matRowDef="let row; columns: exampleCsvColumns"
            ></tr>
          </table>
        </div>
      </mat-card>
      <h2 id="job-feeds-with-html-content">Job Feeds with HTML content</h2>
      <mat-card>
        <h3>Tags allowed by default</h3>
        <code>a, b, br, div, em, hr, i, li, ol, p, span, strong, u, ul</code>
      </mat-card>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<button
  id="back-to-top"
  class="sticky hidden"
  *ngIf="(headerIsVisible$ | async) === false"
  type="button"
  mat-fab
  color="accent"
  (click)="onClick()"
  @fadeIn
  @fadeOut
>
  <span class="cdk-visually-hidden">Back to Top</span>
  <mat-icon fontIcon="keyboard_arrow_up"></mat-icon>
</button>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { BrowserNotSupportedLayoutComponent } from '../layout/browser-not-supported-layout/browser-not-supported-layout.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BecomeAPartnerComponent } from './become-a-partner/become-a-partner.component';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ErrorComponent } from './error/error.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HelpComponent } from './help/help.component';
import { HomeWelcomeComponent } from './home-welcome/home-welcome.component';
import { JobFeedGuidanceComponent } from './job-feed-guidance/job-feed-guidance.component';
import { LegalAndSecurityComponent } from './legal-and-security/legal-and-security.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MediaComponent } from './media/media.component';
import { NoticeAndConsentComponent } from './notice-and-consent/notice-and-consent.component';
import { OurPartnersListComponent } from './our-partners-list/our-partners-list.component';
import { PartnerResourcesComponent } from './partner-resources/partner-resources.component';
import { ViewOurPartnerComponent } from './view-our-partner/view-our-partner.component';
import { PartnerProfileResolverService } from 'core/services/resolvers/partner-profile-resolver.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ResetPasswordResolverService } from 'core/services/resolvers/reset-password-resolver.service';
import { ResetAccountComponent } from './reset-account/reset-account.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { CreateAccountOverviewComponent } from './create-account-overview/create-account-overview.component';
import { AlertsAndInformationComponent } from './alerts-and-information/alerts-and-information.component';
import { PrivacyActStatementComponent } from './privacy-act-statement/privacy-act-statement.component';
import { FaqsResolverService } from 'core/services/resolvers/faqs-resolver.service';
import { CanViewOurPartnerGuard } from 'core/guards/can-view-our-partner.guard';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: HomeWelcomeComponent,
        data: {
          title: 'Military Spouse Employment Partnership Partner Portal',
          description:
            'The Department of Defense Military Spouse Employment Partnership (MSEP) connects military spouses with hundreds of partner employers who have committed to recruit, hire, promote and retain military spouses.',
        },
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
        data: {
          title: 'About the Military Spouse Employment Partnership',
          description:
            'The need to support military spouses, the launch of MSEP, and MSEP today.',
        },
      },
      {
        path: 'alerts-and-information',
        component: AlertsAndInformationComponent,
        data: {
          title: 'Alerts & Information',
          description:
            'Stay informed with the latest updates from the SECO program and the MSEP website.',
        },
      },
      {
        path: 'become-a-partner',
        component: BecomeAPartnerComponent,
        data: {
          title: 'Connecting talented military spouses to committed employers',
          description:
            'The Military Spouse Employment Partnership is a Department of Defense (DoD) led initiative that connects military spouses with hundreds of partner employers who have committed to recruit, hire, promote and retain military spouses.',
        },
      },
      {
        path: 'create-an-account',
        component: ContactUsComponent,
        data: {
          title: 'Create an Account',
          description:
            'Connecting Talented Military Spouses To Committed Employers',
          createAccount: true,
        },
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {
          title: 'Contact the MSEP team',
          description:
            'Use the form below to send us your comments or report any technical difficulties you experience with our website.',
          createAccount: false,
        },
      },
      {
        path: 'error/:exceptionId',
        component: ErrorComponent,
      },
      {
        path: 'faqs',
        component: FaqsComponent,
        resolve: { resolvedData: FaqsResolverService },
        data: {
          title: 'MSEP portal and program FAQs',
          description:
            'Explore the most frequently asked questions regarding the MSEP portal and program.',
        },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'get-started',
        component: CreateAccountOverviewComponent,
        data: {
          title: 'Create an MSEP portal account',
          description:
            "MSEP looks forward to supporting your organization's effort to recruit, hire, promote and retain military spouses.",
        },
      },
      {
        path: 'help',
        component: HelpComponent,
      },
      {
        path: 'job-feed-guidance',
        component: JobFeedGuidanceComponent,
        data: {
          title: 'General job feed guidance',
          description:
            'The MSEP Partner Portal uses an automated importing of job feeds. Specific job feed set up and examples are provided in this section.',
        },
      },
      {
        path: 'legal-and-security',
        component: LegalAndSecurityComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title: 'Log-in to your MSEP account',
          description:
            'Login to your MSEP account or begin registration process.',
        },
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'media',
        component: MediaComponent,
        data: {
          title: 'MSEP videos and related media',
          description:
            'Explore the videos and linked media to provide insight to the conduct and scope of the MSEP program.',
        },
      },
      {
        path: 'notice-and-consent/:path',
        component: NoticeAndConsentComponent,
      },
      {
        path: 'our-partners',
        component: OurPartnersListComponent,
        data: {
          title:
            'A list of all companies and organizations participating in the MSEP Program.',
          description:
            'A detailed profile of each MSEP partner is available for review by clicking on the partner logo.',
        },
      },
      {
        path: 'our-partners/:id',
        component: ViewOurPartnerComponent,
        canActivate: [CanViewOurPartnerGuard],
        resolve: { resolvedData: PartnerProfileResolverService },
        data: {
          title: 'MSEP Partner | MSEP',
          description:
            'A detailed profile of each MSEP partner is available for review, including description, statistics, industry and contact information.',
        },
      },
      {
        path: 'partner-resources',
        component: PartnerResourcesComponent,
        data: {
          title:
            'Explore resources available to enduring and potential MSEP partners',
          description:
            'The resources are collateral materials provided for organizations to prepare for and participate in the MSEP Program.',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyActStatementComponent,
        data: {
          title: 'Privacy Act Statement & Security | MSEP',
          description:
            'Details of the privacy policy, security, and other website regulations for the MyCAA Scholarship program.',
        },
      },
      {
        path: 'reset-account/:token',
        component: ResetAccountComponent,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        resolve: { resolvedData: ResetPasswordResolverService },
      },
      {
        path: 'session-expired',
        component: SessionExpiredComponent,
      },
      {
        path: 'sitemap',
        component: SitemapComponent,
      },
      {
        path: 'user-agreement',
        component: UserAgreementComponent,
        data: {
          title: 'User Agreement | MSEP',
          description:
            'Details of the agreement Users must consent to in regard to their use and conduct within this U.S. Government Information System.',
        },
      },
    ],
  },
  {
    path: '',
    component: BrowserNotSupportedLayoutComponent,
    children: [
      {
        path: 'browser-not-supported',
        component: BrowserNotSupportedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}

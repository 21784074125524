<msep-page-title>
  <h1>Reset Account</h1>
</msep-page-title>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="margin-top-20">
  <msep-server-validation-message></msep-server-validation-message>
  <div class="flex flex-col flex-gt-sm-row justify-between">
    <div class="flex-100">
      <mat-card class="form-section">
        <msep-alert [type]="alertType" title="Select your challenge questions">
          <p>
            Our Records indicate you have not chosen your challenge questions
            and answers yet. In order to complete your password reset, please
            select 3 challenge questions and answers.
          </p>
          <p>
            <strong>NOTE:</strong> Challenge Question answers are case
            sensitive.
          </p>
        </msep-alert>
        <div
          class="margin-top-20 flex flex-col flex-wrap flex-gt-sm-row justify-between"
        >
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label> Challenge Question 1 </mat-label>
            <mat-icon matIconPrefix>how_to_reg</mat-icon>
            <mat-select formControlName="challengeQuestion1" required>
              <ng-container
                *ngFor="let challengeQuestion of challengeQuestions$ | async"
              >
                <mat-option
                  *ngIf="
                    challengeQuestion.id !==
                      form.get('challengeQuestion2')?.value &&
                    challengeQuestion.id !==
                      form.get('challengeQuestion3')?.value
                  "
                  [value]="challengeQuestion.id"
                >
                  {{ challengeQuestion.description }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error>
              <msep-form-error [control]="form.get('challengeQuestion1')">
              </msep-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label>Challenge Question Answer 1</mat-label>
            <input
              [type]="showHidden.get('answer1') ? 'text' : 'password'"
              matInput
              formControlName="challengeAnswer1"
              placeholder="Challenge Question Answer 1"
              required
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (mousedown)="toggleShowHidden('answer1')"
              (mouseup)="toggleShowHidden('answer1')"
              (touchstart)="toggleShowHidden('answer1')"
              (touchend)="toggleShowHidden('answer1')"
              [attr.aria-label]="'Hide answer'"
              [attr.aria-pressed]="showHidden.get('answer1')"
            >
              <mat-icon
                [fontIcon]="
                  showHidden.get('answer1') ? 'visibility' : 'visibility_off'
                "
              ></mat-icon>
            </button>
            <mat-error>
              <msep-form-error
                [control]="form.get('challengeAnswer1')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label> Challenge Question 2 </mat-label>
            <mat-icon matIconPrefix>how_to_reg</mat-icon>
            <mat-select formControlName="challengeQuestion2" required>
              <ng-container
                *ngFor="let challengeQuestion of challengeQuestions$ | async"
              >
                <mat-option
                  *ngIf="
                    challengeQuestion.id !==
                      form.get('challengeQuestion1')?.value &&
                    challengeQuestion.id !==
                      form.get('challengeQuestion3')?.value
                  "
                  [value]="challengeQuestion.id"
                >
                  {{ challengeQuestion.description }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error>
              <msep-form-error [control]="form.get('challengeQuestion2')">
              </msep-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label>Challenge Question Answer 2</mat-label>
            <input
              [type]="showHidden.get('answer2') ? 'text' : 'password'"
              matInput
              formControlName="challengeAnswer2"
              placeholder="Challenge Question Answer 2"
              required
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (mousedown)="toggleShowHidden('answer2')"
              (mouseup)="toggleShowHidden('answer2')"
              (touchstart)="toggleShowHidden('answer2')"
              (touchend)="toggleShowHidden('answer2')"
              [attr.aria-label]="'Hide answer'"
              [attr.aria-pressed]="showHidden.get('answer2')"
            >
              <mat-icon
                [fontIcon]="
                  showHidden.get('answer2') ? 'visibility' : 'visibility_off'
                "
              ></mat-icon>
            </button>
            <mat-error>
              <msep-form-error
                [control]="form.get('challengeAnswer2')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label> Challenge Question 3 </mat-label>
            <mat-icon matIconPrefix>how_to_reg</mat-icon>
            <mat-select formControlName="challengeQuestion3" required>
              <ng-container
                *ngFor="let challengeQuestion of challengeQuestions$ | async"
              >
                <mat-option
                  *ngIf="
                    challengeQuestion.id !==
                      form.get('challengeQuestion1')?.value &&
                    challengeQuestion.id !==
                      form.get('challengeQuestion2')?.value
                  "
                  [value]="challengeQuestion.id"
                >
                  {{ challengeQuestion.description }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error>
              <msep-form-error [control]="form.get('challengeQuestion3')">
              </msep-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label>Challenge Question Answer 3</mat-label>
            <input
              [type]="showHidden.get('answer3') ? 'text' : 'password'"
              matInput
              formControlName="challengeAnswer3"
              placeholder="Challenge Question Answer 3"
              required
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (mousedown)="toggleShowHidden('answer3')"
              (mouseup)="toggleShowHidden('answer3')"
              (touchstart)="toggleShowHidden('answer3')"
              (touchend)="toggleShowHidden('answer3')"
              [attr.aria-label]="'Hide answer'"
              [attr.aria-pressed]="showHidden.get('answer3')"
            >
              <mat-icon
                [fontIcon]="
                  showHidden.get('answer3') ? 'visibility' : 'visibility_off'
                "
              ></mat-icon>
            </button>
            <mat-error>
              <msep-form-error
                [control]="form.get('challengeAnswer3')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="flex flex-col flex-gt-sm-row justify-between">
    <div class="flex-100">
      <mat-card class="form-section">
        <msep-password-checklist
          #checklist
          [password]="
            form.controls['passwordGroup'].get('newPassword')?.value ?? ''
          "
        ></msep-password-checklist>
        <div
          formGroupName="passwordGroup"
          class="margin-top-20 flex flex-col flex-gt-sm-row justify-between"
        >
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label>New Password</mat-label>
            <input
              [type]="showHidden.get('password') ? 'text' : 'password'"
              matInput
              formControlName="newPassword"
              placeholder="New Password"
              required
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (mousedown)="toggleShowHidden('password')"
              (mouseup)="toggleShowHidden('password')"
              (touchstart)="toggleShowHidden('password')"
              (touchend)="toggleShowHidden('password')"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="showHidden.get('password')"
            >
              <mat-icon
                [fontIcon]="
                  showHidden.get('password') ? 'visibility' : 'visibility_off'
                "
              ></mat-icon>
            </button>
            <mat-error>
              <msep-form-error
                [control]="form.get('passwordGroup.newPassword')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            class="flex flex-100 flex-gt-sm-49"
          >
            <mat-label>Confirm New Password</mat-label>
            <input
              [type]="showHidden.get('confirmPassword') ? 'text' : 'password'"
              matInput
              formControlName="newPasswordConfirm"
              placeholder="Confirm New Password"
              required
              [errorStateMatcher]="matcher"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (mousedown)="toggleShowHidden('confirmPassword')"
              (mouseup)="toggleShowHidden('confirmPassword')"
              (touchstart)="toggleShowHidden('confirmPassword')"
              (touchend)="toggleShowHidden('confirmPassword')"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="showHidden.get('confirmPassword')"
            >
              <mat-icon>{{
                showHidden.get('confirmPassword')
                  ? 'visibility'
                  : 'visibility_off'
              }}</mat-icon>
            </button>
            <mat-error>
              <msep-form-error
                [control]="form.get('passwordGroup')"
              ></msep-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="margin-top-20">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!form.valid || isLoading"
      [class.spinner]="isLoading"
    >
      Update
    </button>
  </div>
</form>

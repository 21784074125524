<msep-page-title>
  <h1>About</h1>
  <h2 class="alt">
    <msep-hiring-numbers></msep-hiring-numbers>
  </h2>
</msep-page-title>
<div class="support-panel panel">
  <h2 class="panel-header text-center">The Need To Support Military Spouses</h2>
  <div
    class="margin-top-20 flex flex-col flex-wrap flex-gt-sm-row justify-between grid-gap-16"
  >
    <div class="flex flex-col flex-100 flex-gt-sm-50">
      <mat-card class="flex justify-center align-start">
        <div class="circle-icon margin-auto">
          <mat-icon fontIcon="equalizer"></mat-icon>
        </div>
        <p>
          While military spouses are well educated and highly qualified for a
          range of careers, they face unique challenges due to their spouses'
          military service.
        </p>
        <p>
          They face a
          <a
            href="https://download.militaryonesource.mil/12038/MOS/Infographic/2021-survey-of-active-duty-spouses.pdf"
          >
            21 percent unemployment rate
          </a>
          and a 25 percent wage gap compared to their civilian counterparts.
        </p>
      </mat-card>
    </div>
    <div class="flex flex-100 flex-gt-sm-50">
      <mat-card class="flex flex-col justify-center align-start">
        <div class="circle-icon margin-auto">
          <mat-icon fontIcon="local_shipping"></mat-icon>
        </div>
        <p>
          Seventy-seven percent of these spouses want or need work, yet frequent
          relocation is often a barrier to finding and maintaining a rewarding
          career.
        </p>
        <p>
          The inability of spouses to obtain and retain fulfilling employment as
          they relocate with the military compromises the quality of life of
          military families and the readiness of the military force.
        </p>
      </mat-card>
    </div>
  </div>
</div>

<div class="plain-blue-background panel">
  <div class="flex flex-row">
    <div class="flex-100 flex-gt-md-60">
      <h2 class="panel-header">The Launch of MSEP</h2>
      <p>
        The Presidential Study Directive-9 Report, Strengthening Our Military
        Families: Meeting America's Commitment, published Jan. 25, 2011,
        highlights the need for a unified Federal government approach to help
        develop career and education opportunities for military spouses. Among
        the target activities to accomplish this goal is increasing
        opportunities for military spouses to obtain private sector careers.
      </p>
      <p>
        As a result, the Department of Defense leveraged the successful
        partnerships created under the Army Spouse Employment Partnership by
        expanding the program to Navy, Marine Corps, and Air Force spouses. The
        expanded program - the Military Spouse Employment Partnership, or MSEP -
        officially launched in June 2011 during a ceremony at the U.S. Chamber
        of Commerce.
      </p>
    </div>
    <div class="flex flex-gt-md-40 hide-lt-md justify-center align-center">
      <div class="circle-image circle-image-msep" matRipple></div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="flex flex-col justify-evenly align-center">
    <h2 class="panel-header text-center">MSEP Today</h2>
    <p>
      MSEP, a targeted recruitment and employment solution, creates employment
      connections that provide companies with direct access to military spouses
      seeking career opportunities and spouses with direct access to employers
      who are actively recruiting. MSEP is part of DoD's broader
      <a
        role="link"
        (click)="onClickSeco()"
        aria-label="Spouse Education and Career Opportunities, or SECO - link opens in a new window"
      >
        Spouse Education and Career Opportunities, or SECO
      </a>
      , initiative, which seeks to strengthen the education and career
      opportunities of military spouses by providing:
    </p>
    <mat-card>
      <mat-card-content class="flex flex-col justify-center align-center">
        <mat-list class="msep-today-list">
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="sync_alt"
            ></mat-icon>
            <p>
              <strong>Career exploration</strong>
              opportunities to help them understand their skills, interests, and
              goals
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="school"
            ></mat-icon>
            <p>
              <strong>Education and training</strong> to help them identify
              academic, licensing, or credentialing requirements that can help
              them reach their career goals
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="check"
            ></mat-icon>
            <p>
              <strong>Employment readiness</strong>
              assistance to optimize their self-marketing skills
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="group"
            ></mat-icon>
            <p>
              <strong>Employment connections</strong> that help them find and
              maintain a rewarding career
            </p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>

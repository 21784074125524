<a
  type="button"
  role="link"
  mat-raised-button
  (click)="goBack()"
  (keypress)="goBack()"
>
  <mat-icon
    aria-hidden="false"
    aria-label="Left facing arrow"
    fontIcon="arrow_back"
  >
  </mat-icon>
  Back
</a>

<msep-page-title>
  <h1>Connecting Talented Military Spouses To Committed Employers</h1>
  <h2>
    The Military Spouse Employment Partnership is a Department of Defense (DoD)
    led initiative that connects military spouses with hundreds of partner
    employers who have committed to recruit, hire, promote and retain military
    spouses.
  </h2>
</msep-page-title>
<div class="panel">
  <div class="text-center">
    <h2 class="panel-header">Partner Benefits</h2>
    <p>
      The Military Spouse Employment Partnership gives partners priority access
      to a unique and talented group of professionals — military spouses. They
      are skilled, diverse and motivated, with a strong work ethic. When
      Military Spouse Employment Partnership companies and organizations hire
      military spouses, they gain outstanding employees with 21st century
      skills.
    </p>
  </div>
  <div class="flex flex-row justify-evenly align-center">
    <mat-card class="flex flex-100 flex-gt-sm-90 flex-gt-md-70">
      <mat-card-content>
        <mat-list class="partner-benefits-list">
          <mat-list-item>
            <mat-icon matListItemIcon class="hide-lt-sm">lock_open</mat-icon>
            <p><strong>Unlimited</strong> access to valuable talent</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon class="hide-lt-sm">room_service</mat-icon>
            <p>
              <strong>Convenient</strong>
              solutions for connecting with spouses and uploading job postings
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="star"
            ></mat-icon>
            <p>
              <strong>Recognition</strong>
              opportunities for partners featured in promotional activities,
              social media and at public events
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="public"
            ></mat-icon>
            <p>
              <strong>Networking</strong>
              opportunities with the DoD and other industry-leading partners
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="campaign"
            ></mat-icon>
            <p>
              <strong>Leadership</strong>
              opportunities for partners to participate in and engage with
              ad-hoc working groups, and the Spouse Ambassador Network
            </p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon
              matListItemIcon
              class="hide-lt-sm"
              fontIcon="groups"
            ></mat-icon>
            <p>
              <strong>Partnership</strong>
              development opportunities including LinkedIn partner group,
              Partner-to-partner mentoring, Spouse mentoring and Partner and
              spouse testimonials
            </p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div
  class="panel blue-background partner-commitment flex flex-col justify-evenly align-center"
>
  <strong class="panel-header-label">Expectations</strong>
  <h2 class="panel-header">Partner Commitment</h2>
  <p>
    The Military Spouse Employment Partnership includes industry-leading
    companies and organizations with sound business histories that embrace
    multiple work environments, maximize results and retain a skilled workforce.
    They are a committed group of employers seeking diverse talent with global
    perspectives — qualifications found in military spouses.
  </p>
  <div class="flex flex-row justify-between">
    <div
      class="hide show-gt-sm flex flex-col flex-gt-sm-10 align-center text-center"
    >
      <mat-icon fontIcon="support_agent"></mat-icon>
    </div>
    <div class="flex-col flex-100 flex-gt-sm-80">
      <h3>Statement of Support</h3>
      <p>
        All partners sign the MSEP Statement of Support upon acceptance into the
        partnership. By signing the Statement of Support, partners commit to
        increase employment opportunities for military spouses and, when
        possible, maintaining those opportunities as they relocate. In addition,
        partners strive to provide military spouses who perform well with
        opportunities for career advancement.
      </p>
    </div>
  </div>
  <div class="flex flex-row justify-between margin-top-20">
    <div class="text-center hide show-gt-sm flex-gt-sm-10 align-center">
      <mat-icon fontIcon="insights"></mat-icon>
    </div>
    <div class="flex-100 flex-gt-sm-80">
      <h3>Partner Reporting: Measuring Success</h3>
      <p>
        Measuring success of partner efforts in hiring military spouses is
        critical to the success of the Military Spouse Employment Partnership
        and vital to helping reduce the unemployment rates that have challenged
        the military spouse community for years.
      </p>
      <p>
        MSEP partners are required to track military spouse hires and report
        successes to the program. When employers identify military spouses as
        part of their talent pool, they not only recognize skilled employees
        within the organization, they also create a valuable connection to the
        communities they serve. Partners are expected to report the number of
        spouses for the following categories:
      </p>
      <ul>
        <li>Hired part-time, full-time and virtually</li>
        <li>Spouse employees retained when relocated</li>
        <li>
          Referred to similar employment in other organizations when relocated
        </li>
      </ul>
      <p>
        MSEP partner specialists will work with employer partners to develop a
        strong partnership. This support includes regular virtual meetings and
        recruitment resources to support military spouse hiring. Your MSEP
        partner specialist will share examples of best practices implemented by
        MSEP partners for the last decade, which include developing the ability
        to meet all reporting requirements.
      </p>
    </div>
  </div>
  <div class="margin-top-20 width-100 flex flex-row justify-between">
    <div class="text-center hide show-gt-sm flex-gt-sm-10 align-center">
      <mat-icon fontIcon="military_tech"></mat-icon>
    </div>
    <div class="flex-col flex-100 flex-gt-sm-80">
      <h3>
        Companies And Organizations Fulfill Their Commitment To Support Military
        Spouses By Agreeing To
      </h3>
      <ul>
        <li>
          Identify and promote portable and sustainable career and employment
          opportunities
        </li>
        <li>
          Post job openings and provide a link to your human resources
          employment page on the MSEP Partner Portal
        </li>
        <li>Mentor new MSEP partners</li>
      </ul>
    </div>
  </div>
</div>
<div class="panel flex flex-col justify-evenly align-center">
  <strong class="panel-header-label-alt">Application Process</strong>
  <h2 class="panel-header">How To Get Started</h2>
  <p>
    Organizations interested in joining MSEP should demonstrate a business
    foundation that includes progressive industry experience and positive
    business practices. MSEP also requires that organizations charge no fees or
    costs associated with employment opportunities for military spouses and
    report hiring successes to the program. Excellent candidates for partnership
    offer jobs with geographic portability, access to remote work and offer jobs
    in one or more state or region.
  </p>
  <h3>APPLICATION AND VETTING PROCESS</h3>
  <p>
    If MSEP eligibility requirements are met, organizations are encouraged to
    apply to become an MSEP partner.
  </p>
  <ol>
    <li>
      An organization representative can register for a portal account by
      clicking on the Become a Partner link. For franchises, the headquarters
      office must apply for the entire organization.
    </li>
    <li>
      Organizations can access and complete the application form on the MSEP
      Partner Portal. (Note: VP/Executive level representative must read the
      application and agree to all MSEP criteria before the application is
      submitted.)
    </li>
    <li>
      An MSEP representative reviews the application and conducts additional
      research.
    </li>
    <li>
      The MSEP representative provides the application package to the DoD for
      consideration.
    </li>
    <li>The DoD conducts a phone interview with the potential partner.</li>
    <li>
      The DoD reviews the complete application package and makes a membership
      acceptance recommendation to the Deputy Assistant Secretary of Defense for
      Military Community & Family Policy.
    </li>
    <li>
      If accepted for membership, new partners must provide additional
      information, such as their logo and designated point of contact, sign the
      MSEP Statement of Support, and attend the New Partner Induction Ceremony.
    </li>
  </ol>
  <a
    role="link"
    mat-raised-button
    color="primary"
    [routerLink]="['/get-started']"
  >
    Get Started
  </a>
</div>

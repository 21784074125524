import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ChartsService,
  MapChartFilter,
  MapChartMetrics,
} from 'core/services/charts.service';
import { Observable, BehaviorSubject, delay, tap, switchMap } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import * as d3 from 'd3';
import { legendColor } from 'd3-svg-legend';
import * as topojson from 'topojson-client';
import { Topology, GeometryCollection } from 'topojson-specification';

@Component({
  selector: 'msep-map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.scss'],
})
export class MapChartComponent implements OnInit, AfterViewInit {
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;

  @Input() set filters(value: FormGroup) {
    this.filter = value;
    this.filters.valueChanges.subscribe(newValue => {
      this.filters.patchValue(newValue, { emitEvent: false });
    });
  }
  private filter!: FormGroup;

  isLoading = false;
  mapChartMetrics!: MapChartMetrics[];
  mapChartMetrics$!: Observable<ApiResponse<MapChartMetrics[]>>;
  mapChartMetricsAction$!: Observable<MapChartFilter>;

  private readonly defaultFilter = {
    organizationId: undefined,
  } as MapChartFilter;

  private readonly jobMapMetricsSubject = new BehaviorSubject<MapChartFilter>(
    this.defaultFilter
  );

  get currentFilter(): MapChartFilter {
    return {
      ...this.filter.value,
    };
  }

  constructor(
    private readonly chartsService: ChartsService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadMapData();
  }

  ngAfterViewInit(): void {
    this.createMap();
  }

  private buildForm(): void {
    this.filter = this.formBuilder.group({
      organizationId: [null],
    });
  }

  private calculateUpperBound(upperLimmit: number): number {
    return upperLimmit < 100
      ? Math.ceil(upperLimmit / 10) * 10
      : Math.ceil(upperLimmit / 100) * 100;
  }

  private createMap(): void {
    d3.select('#theChart').selectAll('svg').remove();
    d3.json<Topology<{ states: GeometryCollection }>>(
      'assets/config/us-10m.v1.json'
    ).then(mapData => {
      if (!mapData) {
        console.error('Failed to load map data');
        return;
      }

      const newMapData = topojson.feature(
        mapData,
        mapData.objects.states
      ).features;

      const w = 1200;
      const h = 600;
      const upperLimmit = d3.max(this.mapChartMetrics, d => d.count) ?? 0;

      const svg = d3
        .select('#theChart')
        .append('svg')
        .attr('width', w)
        .attr('height', h)
        .attr('viewBox', '0 0 975 610')
        .attr('style', 'max-width: 100%; height: auto;')
        .attr('align-self', 'center');

      const color = d3.scaleQuantize<string>();

      if (upperLimmit !== 0) {
        color
          .domain([
            1,
            upperLimmit < 10
              ? upperLimmit + 1
              : this.calculateUpperBound(upperLimmit),
          ])
          .range(d3.schemeBlues[upperLimmit > 9 ? 9 : upperLimmit]);
      }

      svg
        .selectAll('path')
        .data(newMapData)
        .enter()
        .append('path')
        .attr('d', d3.geoPath())
        .attr('fill', d => {
          const stateData = this.mapChartMetrics.find(s => s.id === d.id);
          return stateData ? color(stateData.count) : '#ccc';
        })
        .attr('stroke', '#000')
        .attr('stroke-width', 1)
        .append('title')
        .text(d => {
          const stateData = this.mapChartMetrics.find(s => s.id === d.id);
          return stateData ? `${stateData.key}: ${stateData.count}` : 'No data';
        });

      if (this.mapChartMetrics.length > 0) {
        const legend = legendColor()
          .labelFormat(upperLimmit > 1000 ? d3.format('.2s') : d3.format('.0d'))
          .scale(color);

        svg
          .append('g')
          .attr('class', 'legend')
          .attr('transform', `translate(${w - 250},200)`)
          .call(legend);
      }
    });
  }

  private loadMapData(): void {
    this.mapChartMetricsAction$ = this.jobMapMetricsSubject.asObservable();

    this.mapChartMetrics$ = this.mapChartMetricsAction$.pipe(
      delay(0),
      tap(() => (this.isLoading = true)),
      switchMap(() => {
        const filters: MapChartFilter = {
          ...this.filter.value,
        };
        return this.chartsService.getJobMapMetrics(filters);
      }),
      tap(results => {
        this.mapChartMetrics = results.data;
        this.createMap();
        this.isLoading = false;
      })
    );
  }
}

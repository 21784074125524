<header>
  <mat-toolbar class="flex flex-row">
    <div class="flex flex-row flex-100 justify-between align-center">
      <div
        class="flex flex-50 flex-gt-sm-23 flex-gt-md-20 flex-gt-lg-10 msep-header-logo"
      >
        <a
          aria-label="Home"
          [routerLink]="getHomepageLink()"
          class="hide show-gt-xs"
        >
          <img
            class="nav-logo"
            src="assets/images/mos-msep-logo.png"
            alt=" Military One Source and Military Spouse Employment Partnership program logo"
          />
        </a>
        <a
          aria-label="Home"
          [routerLink]="getHomepageLink()"
          class="hide-gt-xs"
        >
          <img
            class="nav-logo-sm"
            src="assets/images/just-stars.png"
            alt="Military Spouse Employment Partnership program logo"
          />
        </a>
      </div>
      <div
        class="flex flex-90 flex-gt-sm-80 flex-gt-lg-70 justify-start align-center hide-lt-md"
        [class]="getHideMenuClass"
      >
        <nav>
          <ul
            class="main-menu"
            *ngIf="isAuthenticated; else unAuthenticatedMenu"
          >
            <li>
              <a aria-label="Home" [routerLink]="getHomepageLink()"> Home </a>
            </li>
            <li *ngIf="!userHasUserRole()">
              <a
                aria-label="Search Partners"
                [routerLink]="['/partner/search']"
              >
                Partners
              </a>
            </li>
            <li *ngIf="shouldShowPartnerDirectoryLink()">
              <a
                aria-label="Partner Directory"
                [routerLink]="['/our-partners']"
              >
                Partner Directory
              </a>
            </li>
            <li *ngIf="userIsSysOp()">
              <button
                mat-button
                class="letter-spacing"
                [matMenuTriggerFor]="adminMenu"
                aria-label="Admin Menu button"
              >
                <span class="drop-down">
                  Admin
                  <mat-icon matSuffix fontIcon="arrow_drop_down"></mat-icon>
                </span>
              </button>

              <mat-menu #adminMenu="matMenu" class="admin-menu top-header-menu">
                <div
                  class="admin-menu-inner flex flex-row justify-between grid-gap-16"
                >
                  <div class="flex-38">
                    <strong class="mat-subheader">
                      <mat-icon fontIcon="report"></mat-icon> CMS
                    </strong>
                    <a
                      role="menuitem"
                      [routerLink]="['/content-management/email-templates']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Email Templates
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/content-management/faqs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      FAQs
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="[
                        '/content-management/manage-360-newsletters'
                      ]"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      360 Newsletters
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="[
                        '/content-management/partner-training-videos'
                      ]"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Partner Training Videos
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/content-management/system-alerts']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      System Alerts
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/government-view']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Government Dashboard
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/specialist-view']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Specialist Dashboard
                    </a>
                  </div>
                  <div class="flex-36">
                    <strong class="mat-subheader">
                      <mat-icon fontIcon="settings_suggest"></mat-icon> General
                    </strong>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/email-health-check']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Email Health Check
                    </a>
                    <a
                      role="menuitem"
                      *ngIf="userHasHelpDeskPermission()"
                      [routerLink]="['/admin/contact-us-tickets']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Help Desk Tickets
                    </a>
                    <a
                      role="menuitem"
                      *ngIf="userHasManageJobsPermission()"
                      [routerLink]="['/admin/job-feeds']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Job Feeds
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/jobs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Job Posts
                    </a>
                    <a
                      role="menuitem"
                      *ngIf="userHasManageReportsPermission()"
                      [routerLink]="['/admin/report-configuration']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Report Configuration
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/test-scheduled-tasks']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Test Scheduled Tasks
                    </a>
                    <a
                      role="menuitem"
                      *ngIf="userHasManageUsersPermission()"
                      [routerLink]="['/admin/users']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Users
                    </a>
                  </div>
                  <div class="flex-26">
                    <strong class="mat-subheader">
                      <mat-icon fontIcon="policy"></mat-icon> Logs
                    </strong>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/authentication-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Authentication
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/event-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Events
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/exception-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Exceptions
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/http-request-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Http Requests
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/job-feed-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Job Feeds
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/scheduled-task-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                    >
                      Scheduled Tasks
                    </a>
                    <a
                      role="menuitem"
                      [routerLink]="['/admin/security-alert-logs']"
                      mat-menu-item
                      routerLinkActive="active-menu-link"
                      class="security-alert"
                    >
                      Security Alerts
                    </a>
                  </div>
                </div>
              </mat-menu>
            </li>
            <li *ngIf="shouldShowReportMenu()">
              <a aria-label="Reports" [routerLink]="['/report']"> Reports </a>
            </li>
            <li *ngIf="!userHasUserRole()">
              <a aria-label="Charts" [routerLink]="['/charts']"> Charts </a>
            </li>
            <li>
              <button
                mat-button
                class="letter-spacing"
                [matMenuTriggerFor]="helpMenu"
                aria-label="Help Menu button"
              >
                <span class="drop-down">
                  Help
                  <mat-icon matSuffix fontIcon="arrow_drop_down"></mat-icon>
                </span>
              </button>
              <mat-menu #helpMenu="matMenu" class="top-header-menu">
                <a
                  role="menuitem"
                  [routerLink]="['/help/contact-us']"
                  mat-menu-item
                  routerLinkActive="active-menu-link"
                >
                  Contact Us
                </a>
                <a
                  role="menuitem"
                  [routerLink]="['/partner-resources']"
                  mat-menu-item
                  routerLinkActive="active-menu-link"
                >
                  Partner Resources
                </a>
              </mat-menu>
            </li>
            <li *ngIf="userHasSpecialistRole() || userIsSysOp()">
              <a
                aria-label="Manage Organizations"
                [routerLink]="['/specialist/manage-partners']"
              >
                Manage Organizations
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div class="flex flex-10 justify-end align-center">
        <div *ngIf="!isAuthenticated" class="login-text">
          <a [routerLink]="['/notice-and-consent', 'login']">Log in</a> /
          <a [routerLink]="['/notice-and-consent', 'create-an-account']"
            >Request Access</a
          >
        </div>
        <div *ngIf="!isAuthenticated" class="become-partner-button">
          <button
            class="become-partner-button"
            [routerLink]="['/become-a-partner']"
            mat-raised-button
            color="accent"
          >
            Become a Partner
          </button>
        </div>
        <msep-published-alerts-icon></msep-published-alerts-icon>
        <button
          mat-icon-button
          (click)="toggle()"
          aria-label="Site Menu button"
        >
          <mat-icon fontIcon="menu"></mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
</header>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"> </mat-progress-bar>

<ng-template #unAuthenticatedMenu>
  <ul class="main-menu">
    <li>
      <a aria-label="Home" routerLink="/"> Home </a>
    </li>
    <li>
      <a aria-label="About Us" [routerLink]="['about-us']"> About </a>
    </li>
    <li>
      <button
        mat-button
        class="letter-spacing"
        [matMenuTriggerFor]="menu"
        aria-label="The Partnership Menu button"
      >
        <span class="drop-down">
          The Partnership
          <mat-icon matSuffix fontIcon="arrow_drop_down"></mat-icon>
        </span>
      </button>
      <mat-menu #menu="matMenu" class="top-header-menu">
        <a role="menuitem" [routerLink]="['/become-a-partner']" mat-menu-item>
          Become a Partner
        </a>
        <a role="menuitem" [routerLink]="['/our-partners']" mat-menu-item>
          Our Partners
        </a>
      </mat-menu>
    </li>
    <li>
      <button
        mat-button
        class="letter-spacing"
        [matMenuTriggerFor]="resourcesMenu"
        aria-label="Resources Menu button"
      >
        <span class="drop-down">
          Resources
          <mat-icon matSuffix fontIcon="arrow_drop_down"></mat-icon>
        </span>
      </button>
      <mat-menu #resourcesMenu="matMenu" class="top-header-menu">
        <a role="menuitem" [routerLink]="['/media']" mat-menu-item> Media </a>
        <a role="menuitem" [routerLink]="['/partner-resources']" mat-menu-item>
          Partner Resources
        </a>
      </mat-menu>
    </li>
    <li>
      <button
        mat-button
        class="letter-spacing"
        [matMenuTriggerFor]="helpMenu"
        aria-label="Help Menu button"
      >
        <span class="drop-down">
          Help
          <mat-icon matSuffix fontIcon="arrow_drop_down"></mat-icon>
        </span>
      </button>
      <mat-menu #helpMenu="matMenu" class="top-header-menu">
        <a
          role="menuitem"
          [routerLink]="['/notice-and-consent', 'contact-us']"
          mat-menu-item
        >
          Contact Us
        </a>
        <a role="menuitem" [routerLink]="['/faqs']" mat-menu-item> FAQs </a>
      </mat-menu>
    </li>
  </ul>
</ng-template>

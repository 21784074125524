<msep-page-title>
  <h1>Sitemap</h1>
</msep-page-title>
<mat-list class="link-list">
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/']">Home</a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/about-us']">About</a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/become-a-partner']">Become a Partner</a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/notice-and-consent', 'contact-us']"
      >Contact Us</a
    >
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/job-feed-guidance']">Job Feed Guidance</a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/media']">Media</a>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListItemIcon fontIcon="arrow_forward"></mat-icon>
    <a role="link" [routerLink]="['/partner-resources']">Partner Resources</a>
  </mat-list-item>
</mat-list>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssistanceType } from 'core/enums';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpDeskService } from 'core/services/help-desk.service';
import { ConfigService } from 'core/services/infrastructure/config.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import {
  ContactUsAssistanceType,
  Resource,
  ResourcesService,
} from 'core/services/resources.service';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, map } from 'rxjs/operators';
import { phoneValidator } from 'shared/validation/phone-number.validator';
import { AlertType } from 'shared/components/alert/alert.component';

@Component({
  selector: 'msep-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit, OnDestroy {
  form!: UntypedFormGroup;
  createAccount?: boolean;
  contactUsAssistanceTypes$!: Observable<ContactUsAssistanceType[]>;
  contactUsContactWindows$ = this.resourcesService.getContactUsContactWindows();
  contactUsPreferredCommunications$ =
    this.resourcesService.getContactUsPreferredCommunications();
  description?: string;
  faqAssistanceTypes = [
    AssistanceType.GeneralProgramQuestions,
    AssistanceType.OtherNotRegistered,
    AssistanceType.OtherRegistered,
  ];
  infoAlertType = AlertType.Info;
  isLoading = false;
  maxMessageLength = 5000;
  recaptchaSiteKey = this.configService.config.recaptchaSiteKey;
  registeredOptions$ = this.resourcesService.getYesNoListBoolean();
  title?: string;

  private submissionFailureMessage = 'Error submitting ticket.';
  private submissionSuccessMessage =
    'Thank you! Your questions or comments have been successfully submitted. We will reach out to you shortly.';
  private subscription: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private formBuilder: UntypedFormBuilder,
    private helpDeskService: HelpDeskService,
    private notificationService: NotificationService,
    private resourcesService: ResourcesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.createAccount = data.createAccount ?? false;
      this.description = data.description;
      this.title = data.title;
    });
    this.buildForm();
    this.getContactUsAssistanceTypes();
  }

  get isGeneralProgramQuestionOrOther(): boolean {
    return this.faqAssistanceTypes.some(
      x => x === this.form.get('contactUsAssistanceTypeId')?.value
    );
  }

  get isJobPostingOrFeeds(): boolean {
    return (
      this.form.get('contactUsAssistanceTypeId')?.value ==
      AssistanceType.JobPostingOrFeeds
    );
  }

  get isRegistered(): boolean {
    return this.form.get('isRegistered')?.value;
  }

  onOrganizationSelected(organization: Resource): void {
    if (organization) {
      this.form.patchValue({
        organizationId: organization.id,
        organizationName: organization.description,
      });
    }
  }

  onRegisteredChange(changeEvent: MatSelectChange): void {
    const { value: isRegistered } = changeEvent;
    if (typeof isRegistered !== 'boolean') return;
    this.form.get('contactUsAssistanceTypeId')?.enable();
    if (this.createAccount) {
      this.form.patchValue({
        contactUsAssistanceTypeId: isRegistered
          ? AssistanceType.NewUserAccount
          : AssistanceType.BecomeMSEPPartner,
      });
    } else {
      this.form.patchValue({ contactUsAssistanceTypeId: null });
    }
    if (!isRegistered) {
      // remove organization information if user is not a current partner
      this.form
        .get('nonPartnerOrganizationName')
        ?.setValidators([Validators.required, Validators.maxLength(255)]);
      this.form
        .get('nonPartnerOrganizationWebsite')
        ?.setValidators([Validators.required, Validators.maxLength(255)]);
      this.form.patchValue({ organizationId: null, organizationName: null });
    } else {
      this.clearNonPartnerValidators();
    }
  }

  onSubmit(): void {
    this.isLoading = true;

    this.subscription = this.helpDeskService
      .createHelpDeskTicket(this.form.value, this.form.value.reCaptcha)
      .subscribe({
        next: () => {
          this.notificationService.emitSuccess(this.submissionSuccessMessage);
          this.router.navigate(['/']);

          this.isLoading = false;
        },
        error: () => {
          this.notificationService.emitFailure(this.submissionFailureMessage);
          this.isLoading = false;
        },
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.maxLength(50)]],
      email: [
        null,
        [Validators.required, Validators.email, Validators.maxLength(256)],
      ],
      phone: [null, [Validators.required, phoneValidator]],
      isRegistered: [null, Validators.required],
      contactUsAssistanceTypeId: [
        { value: null, disabled: true },
        Validators.required,
      ],
      nonPartnerOrganizationName: null,
      nonPartnerOrganizationWebsite: null,
      organizationId: null,
      organizationName: null,
      contactUsContactWindowId: [null, Validators.required],
      contactUsPreferredCommunicationId: [null, Validators.required],
      message: [
        null,
        [Validators.required, Validators.maxLength(this.maxMessageLength)],
      ],
      reCaptcha: [null, Validators.required],
    });
  }

  private clearNonPartnerValidators(): void {
    this.form.get('nonPartnerOrganizationName')?.clearValidators();
    this.form.get('nonPartnerOrganizationWebsite')?.clearValidators();
    this.form.get('nonPartnerOrganizationName')?.updateValueAndValidity();
    this.form.get('nonPartnerOrganizationWebsite')?.updateValueAndValidity();
  }

  private getContactUsAssistanceTypes(): void {
    this.contactUsAssistanceTypes$ = this.form.valueChanges.pipe(
      map(values => values.isRegistered as boolean | undefined),
      distinctUntilChanged(),
      concatMap(isRegistered => {
        if (typeof isRegistered !== 'boolean') return EMPTY;
        return this.resourcesService.getContactUsAssistanceTypes(isRegistered);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

<msep-page-title>
  <h1>Log in to your MSEP account</h1>
  <h2>Connecting Talented Military Spouses to Committed Employers</h2>
</msep-page-title>

<div class="flex flex-100 justify-center align-center">
  <div
    class="flex flex-100 flex-gt-md-70 gap-30 flex-col flex-gt-sm-row justify-center align-center align-gt-sm-start"
  >
    <div
      class="flex flex-100 flex-gt-sm-50 justify-center align-center align-gt-sm-start"
    >
      <mat-card class="login">
        <mat-card-title class="text-center"> Log in </mat-card-title>
        <mat-card-content>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <msep-server-validation-message></msep-server-validation-message>
            <div class="flex flex-col gap-1rem">
              <mat-form-field class="flex" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="Enter your email address"
                  formControlName="email"
                  required
                />
                <mat-icon matIconPrefix>alternate_email</mat-icon>
                <mat-error>
                  <msep-form-error
                    [control]="form.get('email')"
                  ></msep-form-error>
                </mat-error>
                <mat-hint>Example: name&#64;example.com</mat-hint>
              </mat-form-field>
              <mat-form-field class="flex" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  matInput
                  placeholder="Enter your password"
                  formControlName="password"
                  required
                  [autocomplete]="showPassword ? 'off' : 'current-password'"
                />
                <button
                  type="button"
                  mat-icon-button
                  matSuffix
                  (mousedown)="toggleShowPassword()"
                  (mouseup)="toggleShowPassword()"
                  (touchstart)="toggleShowPassword()"
                  (touchend)="toggleShowPassword()"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="showPassword"
                >
                  <mat-icon
                    [fontIcon]="showPassword ? 'visibility' : 'visibility_off'"
                  ></mat-icon>
                </button>
                <mat-error>
                  <msep-form-error
                    [control]="form.get('password')"
                  ></msep-form-error>
                </mat-error>
                <mat-icon matIconPrefix>lock</mat-icon>
              </mat-form-field>
            </div>
            <section class="flex flex-col gap-8">
              <button
                type="submit"
                mat-raised-button
                color="primary"
                [disabled]="!form.valid || isLoading"
                [class.spinner]="isLoading"
              >
                Log in
              </button>
              <div class="flex align-center">
                <a role="link" routerLink="/forgot-password"
                  >Forgot your password?</a
                >
              </div>
            </section>
          </form>
        </mat-card-content>
        <mat-card-footer *ngIf="isLoading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
    <div class="help flex flex-100 flex-gt-sm-50 gap-20 flex-col">
      <div class="flex justify-start flex-col divider-container">
        <h2>Need Help?</h2>
        <mat-divider></mat-divider>
      </div>
      <div class="flex flex-100 gap-40 flex-col">
        <div class="flex gap-15 flex-col">
          <div class="flex flex-col">
            <strong>
              My organization is interested in becoming a MSEP Partner.
            </strong>
            <span>
              If your organization is interested in becoming a MSEP partner, or
              you are interested in gaining access to your organization's MSEP
              account, our Help Desk team is ready to assist you. Click below to
              get started!
            </span>
          </div>
          <div>
            <button
              mat-stroked-button
              color="primary"
              [routerLink]="['/become-a-partner']"
            >
              Become a Partner
            </button>
          </div>
        </div>
        <div class="flex gap-15 flex-col">
          <div class="flex flex-col">
            <strong>
              My company is already an MSEP Partner. How do I register for
              access to the MSEP Portal?
            </strong>
            <span>
              To register for an account with an existing MSEP Partner, contact
              your organization's primary POC and ask them to send a contact us
              ticket. If you need further assistance, contact our MSEP Support
              Team.
            </span>
          </div>
          <div>
            <button
              href
              [routerLink]="['/notice-and-consent', 'create-an-account']"
              mat-stroked-button
              color="primary"
            >
              Create an Account
            </button>
          </div>
        </div>
        <div class="flex gap-15 flex-col">
          <div class="flex flex-col">
            <strong> Are you a military spouse? </strong>
            <span>
              The Spouse Education and Career Opportunities (MySECO) Portal
              provides education and career guidance to military spouses
              worldwide, offering comprehensive resources and tools related to
              career exploration, education, training and licensing, employment
              readiness and career connections. Call 800-342-9647 to speak with
              a certified career coach.
            </span>
          </div>
          <div class="flex gap-10">
            <button
              mat-stroked-button
              color="primary"
              (click)="onClickSecoGettingStarted()"
            >
              Register for MySECO
            </button>
            <button mat-stroked-button color="primary" (click)="onClickSeco()">
              Search for Jobs
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

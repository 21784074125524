import { Component, Input, OnDestroy } from '@angular/core';
import { BlobService } from 'core/services/blob.service';
import { ChartsService, MapChartFilter } from 'core/services/charts.service';
import { NotificationService } from 'core/services/infrastructure/notification.service';
import { Observable, Subscription } from 'rxjs';
import { ServerBlobResult } from 'shared/models/server-blob-result';

@Component({
  selector: 'msep-export-map-chart-button',
  templateUrl: './export-map-chart-button.component.html',
})
export class ExportMapChartButtonComponent implements OnDestroy {
  @Input() organizationId?: number;
  @Input() filters!: MapChartFilter;

  isLoading = false;
  chartsObservable$!: Observable<ServerBlobResult>;

  private subscriptions: Subscription[] = [];

  constructor(
    private blobService: BlobService,
    private chartsService: ChartsService,
    private notificationService: NotificationService
  ) {}

  onExportClick(): void {
    this.isLoading = true;
    this.setJobMapObservable();
    const subscription = this.chartsObservable$.subscribe({
      next: result => {
        this.blobService.download(result);
      },
      error: () => {
        this.notificationService.emitFailure(
          'Error exporting chart data. Please contact an administrator'
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(x => x.unsubscribe());
  }

  private setJobMapObservable(): void {
    this.chartsObservable$ = this.chartsService.exportJobMapMetrics(
      this.filters
    );
  }
}

<div class="masthead panel flex flex-col justify-center">
  <div class="flex flex-col flex-gt-md-row grid-gap-20 grid-gap-gt-lg-40">
    <div class="flex-100 flex-gt-sm-33-33">
      <img
        src="assets/images/white_msep_logo.png"
        alt=" Military One Source and Spouse Education and Career Opportunities program logo"
      />
    </div>
    <div class="flex-100 flex-gt-sm-67">
      <strong class="panel-header-label">Welcome</strong>
      <h1 class="alt">Employers</h1>
      <p>
        The Department of Defense Military Spouse Employment Partnership (MSEP)
        connects military spouses with hundreds of partner employers who have
        committed to recruit, <strong class="highlight">hire</strong>, promote
        and retain <strong class="highlight">military spouses</strong>.
      </p>
      <div class="masthead-buttons">
        <a
          role="link"
          [routerLink]="['/become-a-partner']"
          mat-raised-button
          color="primary"
        >
          Become a partner
        </a>
        <a
          role="link"
          [routerLink]="['/notice-and-consent', 'login']"
          mat-raised-button
          color="accent"
        >
          Log in
        </a>
      </div>
    </div>
  </div>
</div>

<div class="our-mission blue-background panel">
  <div class="flex flex-col justify-evenly align-center">
    <strong class="panel-header-label">Who are we?</strong>
    <h2 class="panel-header alt">Our Mission</h2>
    <p class="alt">
      <msep-hiring-numbers></msep-hiring-numbers>
    </p>
    <mat-card>
      <mat-card-content>
        <p class="text-center">
          The Military Spouse Employment Partnership is a targeted recruitment
          and employment solution for spouses and companies that:
        </p>
        <div class="flex flex-col align-center justify-center">
          <mat-list class="our-mission-list">
            <mat-list-item>
              <mat-icon
                matListItemIcon
                class="hide-lt-md"
                fontIcon="sync_alt"
              ></mat-icon>
              <p>
                <strong>Connects</strong> corporate and non-profit organizations
                with military spouses representing ALL Military Services.
              </p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon
                matListItemIcon
                class="hide-lt-md"
                fontIcon="room_service"
              ></mat-icon>
              <p>
                <strong>Provides</strong> human resource managers with
                recruitment solutions - military spouses.
              </p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon
                matListItemIcon
                class="hide-lt-md"
                fontIcon="check"
              ></mat-icon>
              <p>
                <strong>Prepares</strong> military spouses to become
                competitive, "job ready" applicants.
              </p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon
                matListItemIcon
                class="hide-lt-md"
                fontIcon="group"
              ></mat-icon>
              <p>
                <strong>Connects</strong> military spouses with employers
                seeking the essential 21st century workforce skills and
                attributes they possess.
              </p>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="text-center our-mission-buttons">
          <a
            role="link"
            [routerLink]="['/about-us']"
            mat-raised-button
            color="primary"
          >
            About Us
          </a>
          <a role="link" [routerLink]="['/our-partners']" mat-raised-button>
            View Our Partners
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="panel flex flex-col justify-evenly align-center">
  <div class="text-center">
    <strong class="panel-header-label-alt">Who works with us?</strong>
    <h2 class="panel-header">Our Partners</h2>
  </div>
  <ng-container *ngIf="randomPartners$ | async">
    <div
      class="row-in-column flex flex-row flex-wrap justify-between grid-gap-8 grid-gap-gt-sm-16"
      *ngIf="partners"
    >
      <div
        class="flex flex-100 flex-gt-sm-24 flex-gt-lg-12"
        *ngFor="let partner of partners"
      >
        <mat-card
          matRipple
          class="flex flex-100 partner-card partner-logo-card"
        >
          <a role="link" [routerLink]="['/our-partners', partner.id]">
            <div class="image-container flex justify-center">
              <img
                mat-card-image
                [src]="partner.logoUrl"
                [alt]="partner.organizationName"
              />
            </div>
          </a>
        </mat-card>
      </div>
    </div>
  </ng-container>
  <msep-ghost-loader-list
    class="row-in-column flex flex-wrap flex-row justify-between align-stretch grid-gap-8 grid-gap-gt-sm-16"
    [type]="ghostLoaderType"
    [ghostLength]="ghosts"
    [childFlex]="100"
    [childFlexGtSm]="24"
    [childFlexGtLg]="12"
    *ngIf="!partners"
  ></msep-ghost-loader-list>
</div>

<div
  class="partnership-panel blue-background panel flex flex-col justify-evenly align-center"
>
  <div class="text-center">
    <strong class="panel-header-label">
      Is your organization a great fit?
    </strong>
    <h2 class="panel-header alt">Partnership Types</h2>
    <p>
      As an industry leader and MSEP employer, your company or organization can
      support military families while increasing your workforce and
      strengthening your business. If MSEP eligibility requirements are met,
      companies and organizations are encouraged to apply to one of the
      following partnership types:
    </p>
  </div>
  <div class="flex-row" style="width: 100%">
    <div class="partnership-type-tab-group flex flex-100">
      <mat-tab-group [selectedIndex]="selectedPartnerIndex" dynamicHeight>
        <mat-tab label="EMPLOYER PARTNERS">
          <mat-card>
            <p>
              Organizations and businesses that are committed to providing
              direct employment opportunities and career support for military
              spouses on a local, national, or worldwide basis.
            </p>
            <ul>
              <li>
                <strong>Federal Agency Partners:</strong> Federal government
                departments and agencies that work with MSEP to identify and
                support military spouse candidates seeking federal employment
                opportunities.
              </li>
              <li>
                <strong>Education:</strong> Accredited primary, secondary and
                higher education institutions that provide direct employment
                opportunities to military spouses on a local, regional, or
                national basis. While the primary focus is on targeting
                educators, MSEP Education includes all employment opportunities
                within a school system (e.g., teachers, counselors, nurses,
                administrators, and food service workers).
              </li>
              <li>
                <strong>Small Business:</strong> A specialized employer program
                offering small businesses near local installations the
                opportunity to support spouse employment efforts within military
                impacted communities.
              </li>
            </ul>
            <a
              role="link"
              [routerLink]="['/become-a-partner']"
              mat-raised-button
              color="primary"
            >
              Become A Partner
            </a>
          </mat-card>
        </mat-tab>
        <mat-tab label="SPOUSE AMBASSADOR NETWORK">
          <mat-card>
            <p>
              A committed group of organizations within the Military Spouse
              Employment Partnership that has community networks and leverages
              those networks to broaden knowledge about military spouse career
              resources in communities where military spouses live and work.
            </p>
            <a
              role="link"
              [routerLink]="['/become-a-partner']"
              mat-raised-button
              color="primary"
            >
              Become A Partner
            </a>
          </mat-card>
        </mat-tab>
        <mat-tab label="COMMUNITY ACTION NETWORK">
          <mat-card>
            <p>
              Community Action Networks (CAN) are comprised of local civic and
              business organizations focused on educating local employers on the
              need for employing military spouses and the impact on defense
              readiness as well as local economies. CAN networks unify under the
              challenge of military spouse unemployment and network on behalf of
              MSEP, driving awareness of program resources and employment
              opportunities for military spouses at the local level.
            </p>
            <p>
              For more information on MSEP CAN, call us at 877-940-MSEP (6737).
            </p>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div class="testimonial-panel panel flex flex-col justify-center align-center">
  <strong class="panel-header-label-alt">What MSEP partners are saying</strong>

  <h2 class="panel-header">Partner Testimonials</h2>

  <p>
    We hope you will join the hundreds of MSEP employers in the commitment to
    recruit, hire, promote and retain military spouses to support our military
    families in their service to our nation.
  </p>

  <mat-card>
    <div class="flex flex-col justify-center align-center">
      <p>
        As an organization that serves military members and their families,
        we're honored to have team members who are military spouses throughout
        our organization. They live and breathe the military lifestyle, and can
        relate to our members, allowing them to provide exceptional member
        service. We're grateful for their service to our country and to our
        members.
      </p>
      <div class="testimonial-logo">
        <img
          mat-card-image
          [src]="navyFederalLogo"
          alt="Navy Federal Credit Union"
        />
      </div>
    </div>
  </mat-card>
</div>

<div
  class="job-search-panel blue-background panel flex flex-column align-center"
>
  <div class="flex flex-row justify-center align-center">
    <div class="flex-100 flex-gt-md-60 justify-center align-center">
      <strong class="panel-header-label">Are you a military spouse?</strong>
      <h2 class="panel-header alt">Search for jobs with MSEP Partners</h2>
      <p>
        Find employment opportunities with hundreds of companies and
        organizations committed to recruiting, hiring, promoting and retaining
        military spouses in portable careers. Call 800-342-9647.
      </p>
      <a role="link" (click)="onClickSeco()" mat-raised-button> Search Jobs </a>
    </div>
    <div class="flex flex-gt-md-40 justify-center align-center hide-lt-md">
      <div class="circle-image circle-image-spouse" matRipple></div>
    </div>
  </div>
</div>

<msep-page-title>
  <h1>{{ title }}</h1>
  <h2>
    {{ description }}
  </h2>
</msep-page-title>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <msep-server-validation-message></msep-server-validation-message>
  <msep-alert *ngIf="createAccount">
    The Account creation process is exclusively for organizations and their
    employees who wish to partner with the DoD MSEP Program. Please refer to the
    <a [routerLink]="['/become-a-partner']">latest guidance</a> about becoming
    an MSEP Partner, and carefully review the information below.
  </msep-alert>
  <msep-alert
    *ngIf="!createAccount && isGeneralProgramQuestionOrOther"
    [type]="infoAlertType"
    title="Frequently Asked Questions"
  >
    <p>
      Have you reviewed our
      <a role="link" class="link" [routerLink]="['/faqs']">FAQ page</a> yet? You
      can find answers to common MSEP questions.
    </p>
  </msep-alert>
  <msep-alert
    *ngIf="isJobPostingOrFeeds"
    [type]="infoAlertType"
    title="Job Feed Guidance"
  >
    <p>
      Have you reviewed our
      <a role="link" class="link" [routerLink]="['/job-feed-guidance']"
        >Job Feed Guidance</a
      >
      page yet? You can find answers to common job feed questions.
    </p>
  </msep-alert>
  <mat-card class="form-section">
    <div
      class="flex flex-col flex-gt-sm-wrap flex-gt-sm-row justify-between grid-gap-15"
    >
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label>First Name</mat-label>
        <mat-icon matIconPrefix fontIcon="person"></mat-icon>
        <input
          matInput
          placeholder="First Name"
          type="text"
          formControlName="firstName"
          required
        />
        <mat-error>
          <msep-form-error [control]="form.get('firstName')"> </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label>Last Name</mat-label>
        <mat-icon matIconPrefix fontIcon="person"></mat-icon>
        <input
          matInput
          placeholder="Last Name"
          type="text"
          formControlName="lastName"
          required
        />
        <mat-error>
          <msep-form-error [control]="form.get('lastName')"> </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label>Email Address</mat-label>
        <mat-icon matIconPrefix fontIcon="alternate_email"></mat-icon>
        <input
          matInput
          placeholder="Email Address"
          type="text"
          formControlName="email"
          required
        />
        <mat-error>
          <msep-form-error [control]="form.get('email')"> </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label>Phone Number</mat-label>
        <mat-icon matIconPrefix fontIcon="smartphone"></mat-icon>
        <input
          matInput
          placeholder="Phone Number—e.g. 123-456-7890"
          type="tel"
          formControlName="phone"
          required
        />
        <mat-error>
          <msep-form-error [control]="form.get('phone')"> </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label> Is your organization an MSEP Partner? </mat-label>
        <mat-icon matIconPrefix fontIcon="how_to_reg"></mat-icon>
        <mat-select
          formControlName="isRegistered"
          required
          (selectionChange)="onRegisteredChange($event)"
        >
          <mat-option
            *ngFor="let registeredOption of registeredOptions$ | async"
            [value]="registeredOption.value"
          >
            {{ registeredOption.description }}
          </mat-option>
        </mat-select>
        <mat-error>
          <msep-form-error [control]="form.get('isRegistered')">
          </msep-form-error>
        </mat-error>
      </mat-form-field>
      <msep-partner-autocomplete
        label="Which organization do you belong to?"
        formControlName="organizationName"
        (partnerSelected)="onOrganizationSelected($event)"
        class="flex flex-100 flex-gt-sm-45"
        *ngIf="isRegistered"
      ></msep-partner-autocomplete>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label> Area of Assistance </mat-label>
        <mat-icon matIconPrefix fontIcon="help_outline"></mat-icon>
        <mat-select formControlName="contactUsAssistanceTypeId" required>
          <mat-option
            *ngFor="let assistanceType of contactUsAssistanceTypes$ | async"
            [value]="assistanceType.id"
          >
            {{ assistanceType.description }}
          </mat-option>
        </mat-select>
        <mat-error>
          <msep-form-error [control]="form.get('contactUsAssistanceTypeId')">
          </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="flex flex-100 flex-gt-sm-45"
        appearance="outline"
        *ngIf="isRegistered === false"
      >
        <mat-label>Organization Name</mat-label>
        <mat-icon matIconPrefix fontIcon="work"></mat-icon>
        <input
          matInput
          placeholder="Organization Name"
          type="text"
          formControlName="nonPartnerOrganizationName"
          required
        />
        <mat-error>
          <msep-form-error [control]="form.get('organizationName')">
          </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="flex flex-100 flex-gt-sm-45"
        appearance="outline"
        *ngIf="isRegistered === false"
      >
        <mat-label>Organization Website</mat-label>
        <mat-icon matIconPrefix fontIcon="language"></mat-icon>
        <input
          matInput
          placeholder="Organization Website"
          type="text"
          formControlName="nonPartnerOrganizationWebsite"
          required
        />
        <mat-error>
          <msep-form-error [control]="form.get('organizationWebsite')">
          </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label> What is your preferred method of communication? </mat-label>
        <mat-icon matIconPrefix fontIcon="perm_phone_msg"></mat-icon>
        <mat-select
          formControlName="contactUsPreferredCommunicationId"
          required
        >
          <mat-option
            *ngFor="
              let preferredCommunication of contactUsPreferredCommunications$
                | async
            "
            [value]="preferredCommunication.id"
          >
            {{ preferredCommunication.description }}
          </mat-option>
        </mat-select>
        <mat-error>
          <msep-form-error
            [control]="form.get('contactUsPreferredCommunicationId')"
          >
          </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-45" appearance="outline">
        <mat-label> What is the best time to contact you? </mat-label>
        <mat-icon matIconPrefix fontIcon="schedule"></mat-icon>
        <mat-select formControlName="contactUsContactWindowId" required>
          <mat-option
            *ngFor="let window of contactUsContactWindows$ | async"
            [value]="window.id"
          >
            {{ window.description }}
          </mat-option>
        </mat-select>
        <mat-error>
          <msep-form-error [control]="form.get('contactUsContactWindowId')">
          </msep-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex flex-100 flex-gt-sm-100" appearance="outline">
        <mat-label>Questions & Comments</mat-label>
        <textarea
          matInput
          #message
          placeholder="Enter your questions, comments and your organization here"
          formControlName="message"
          maxlength="5000"
          rows="5"
          required
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
        ></textarea>
        <mat-hint align="end"
          >{{ message.value.length }} / {{ maxMessageLength }}</mat-hint
        >
        <mat-error>
          <msep-form-error [control]="form.get('message')"> </msep-form-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-col justify-center align-center">
      <p>
        <re-captcha
          formControlName="reCaptcha"
          [siteKey]="recaptchaSiteKey"
        ></re-captcha>
      </p>
    </div>
  </mat-card>
  <div class="flex flex-wrap justify-center align-center">
    <div class="flex flex-row justify-center align-end">
      <msep-back-button></msep-back-button>
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="!form.valid || isLoading"
        [class.spinner]="isLoading"
      >
        Submit
      </button>
    </div>
  </div>
</form>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiResponse } from 'shared/models/api-response';
import { ConfigService } from '../infrastructure/config.service';

@Injectable({ providedIn: 'root' })
export class PartnerDashboardService {
  private readonly msepPartnerApiUrl =
    this.configService.config.msepPartnerApiBaseUrl;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  getAlerts(organizationId: number): Observable<PartnerDashboardAlerts> {
    const url = `${this.msepPartnerApiUrl}/partners/${organizationId}/dashboard/alerts`;
    return this.http.get<PartnerDashboardAlerts>(url).pipe(
      map(result => result),
      catchError(this.handleError)
    );
  }

  getPartnerActivity(organizationId: number): Observable<PartnerActivity> {
    const url = `${this.msepPartnerApiUrl}/partners/${organizationId}/partner-activity`;
    return this.http.get<ApiResponse<PartnerActivity>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  getPartnerInfo(organizationId: number): Observable<PartnerInfo> {
    const url = `${this.msepPartnerApiUrl}/partners/${organizationId}/partner-info`;
    return this.http.get<ApiResponse<PartnerInfo>>(url).pipe(
      map(result => result.data),
      catchError(this.handleError)
    );
  }

  getPartnerScoreFromSap(organizationId: number): Observable<ScoreResult> {
    const url = `${this.msepPartnerApiUrl}/partners/${organizationId}/sap-partner-info`;
    return this.http.get<ScoreResult>(url).pipe(
      map(result => result),
      catchError(this.handleError)
    );
  }

  logAccess(organizationId: number): Observable<unknown> {
    const url = `${this.msepPartnerApiUrl}/partners/${organizationId}/log-access`;
    return this.http
      .post(url, { organizationId: organizationId })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error || 'Server error');
  }
}

export interface MandatoryUpdates {
  hasMandatoryUpdates: boolean;
  noCandidateSearchLast30Days: boolean;
  noPostedJobsLast30Days: boolean;
  noSpouseHiresLast90Days: boolean;
  noHiringReportLast30Days: boolean;
}

export interface PartnerActivity {
  spousesCurrentlyEmployed: number;
  totalPublishedJobs: number;
  totalSpousesHired: number;
  candidateSearchCount: number;
  completedMonthlyReportCount: number;
  candidateProfilesViewedCount: number;
}

export interface PartnerInfo {
  partnerId: number;
  partnerName: string;
  partnerLogoUrl: string;
  partnershipType: number[];
  inductedDate: Date;
  resources: string[];
  score: number | null;
  statusId: number;
  status: string;
  specialistName?: string;
  specialistEmail?: string;
}

export interface PartnerDashboardAlerts {
  isReportingWindowOpen: boolean;
  mandatoryUpdates?: MandatoryUpdates;
}

export interface ScoreResult {
  score: number | null;
  error: boolean;
}
